<template>
  <div class="flex items-stretch bg-body min-h-screen bg-theme-bg">
    <!-- Navbar -->
    <div
      class="flex md:justify-center justify-between fixed top-0 left-0 w-full bg-white shadow-nav items-center py-4 z-50 px-5 md:px-12"
    >
      <router-link
        to="/"
        class="inline-flex items-center space-x-1 text-theme-body uppercase font-medium text-sm md:absolute left-12"
        ><Icon name="left-arrow" class="w-6 h-6" />
        <span>Back to Cart</span></router-link
      >
      <router-link to="/home">
        <!-- <img src="/images/logos/medusa-halloween.svg" class="h-7 w-auto"/> -->
        <img  :src="logoUrl.url" class="h-7 w-auto" />

      </router-link>
    </div>
    <div class="flex items-stretch w-full min-h-screen">
      <div class="contents">
        <div class="inner-wrapper">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "CheckoutLayout",

   data() {
    return {
      logoUrl: ""
    };
  },

  mounted() {
    axios.get(process.env.BASE_URL_API+"/logo/get").then((res) => {
      this.logoUrl = res.data;
    });
  },

};
</script>

<style lang="scss" scoped>
.contents {
  @apply block h-full flex-grow pt-12 mx-auto;
  @include maxContainer();

  .inner-wrapper {
    @apply px-4 md:px-8 py-10;
  }
}
</style>
